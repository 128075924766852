<template>
  <div class="h-full flex flex-col">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row">
      <p class="font-bold">generate report by:</p>
    </div>
    <div class="form-row">
      <button
        class="main-topic button-color text-gray-500 focus:outline-none font-semibold py-2 px-4 border"
        :class="{ activeCatg: 'main-topic' === selectedCatg }"
        @click="selectedCatg = 'main-topic'">
        main topic
      </button>
      <button
        class="customer button-color text-gray-500 focus:outline-none font-semibold py-2 px-4 border"
        :class="{ activeCatg: 'customer' === selectedCatg }"
        @click="selectedCatg = 'customer'">
        customer
      </button>
    </div>
    <div v-if="selectedCatg === 'main-topic'" class="flex flex-col flex-1 overflow-auto">
      <div class="form-row">
        <div class="form-col">
          <label for="categoryReport">main topic</label>
          <Dropdown
            id="categoryReport"
            label="intent_category_name"
            track-by="description"
            :options="categories"
            :value="categoryReport"
            @input="setCategoryValue"
            objectMode
            multiple
          />
        </div>
      </div>
      <div v-if="this.isMainTopic">
        <p class="font-bold">report format</p>
        <span class="form-hint mb-5">your download will begin automatically after clicking the export to excel button</span>
        <div class="flex-1 flex-col overflow-auto">
          <List
            :key="this.categoryDescriptions.length"
            resource="previewCustomResponsesTopicReport"
            :fields="topicFields"
            ref="list_main_topic"
            :pageSize="18"
            trackBy="intent_response_id"
            :requestParams="{ topic: this.categoryDescriptions, sortField: '', direction: '' }"
            :search="true"
          />
        </div>
      </div>
    </div>
    <div v-else class="flex flex-col flex-1 overflow-auto">
      <p class="font-bold">report format</p>
      <span class="form-hint mb-5">your download will begin automatically after selecting a type</span>
      <div class="flex-1 flex-col overflow-auto">
        <List
          resource="previewCustomResponsesCustomerReport"
          :fields="fields"
          ref="list_customer"
          :pageSize="18"
          trackBy="intent_response_id"
          :search="true"
          :requestParams="{sortField: '', direction: ''}"
        />
      </div>
    </div>
    <modal-footer :footer="footer" :primary="onDownload" :tertiary="cancel"  />
  </div>
</template>

<script>
import GlobalResponsesMixin from '@/components/digital_human/GlobalResponsesMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import ModalFooter from '@/components/ui/modals/ModalFooter'
import NotifyMixin from '@/mixins/NotifyMixin'
import Loader from '@/components/ui/Loader'
import Dropdown from '@/components/ui/Dropdown'
import EventBus from "@/utils/EventBus"
import List from '@/components/digital_human/List'

export default {
  name: 'CreateResponse',
  components: { Loader, ModalFooter, Dropdown, List },
  mixins: [ModalNavigation, NotifyMixin, GlobalResponsesMixin],
   data() {
    return {
      footer: {
        primaryButton: 'export to excel',
        tertiaryButton: 'cancel',
      },
      fitleExtention: '',
      selectedCatg: 'main-topic',
      isMainTopic: false,
      topicFields: [
        {
          name: 'intent_category_name',
          title: 'main topic',
          sortField: 'intent_category_name'
        },
        {
          name: 'intent_name_parent',
          title: 'conversation detail',
          sortField: 'intent_name_parent',
        },
        {
          name: 'intent_name_child',
          title: 'next level detail',
          sortField: 'intent_name_child'
        },
        {
          name: 'customer',
          title: 'customer',
          sortField: 'customer'
        },
        {
          name: 'user_name',
          title: 'person',
          sortField: 'user_name'
        },
        {
          name: 'community_name',
          title: 'community',
          sortField: 'community_name'
        },
        {
          name: 'flagged',
          title: 'flagged',
          sortField: 'flagged'
        },
        {
          name: 'blocked',
          title: 'frozen',
          sortField: 'blocked'
        },
        {
          name: 'approved',
          title: 'reviewed',
          sortField: 'approved'
        },
        {
          name: 'response_text',
          title: 'response',
          sortField: 'response_text'
        },
      ],
      fields: [
        {
          name: 'customer',
          title: 'customer',
          sortField: 'customer'
        },
        {
          name: 'community_name',
          title: 'community',
          sortField: 'community_name'
        },
        {
          name: 'user_name',
          title: 'person',
          sortField: 'user_name'
        },
        {
          name: 'intent_category_name',
          title: 'main topic',
          sortField: 'intent_category_name'
        },
        {
          name: 'flagged',
          title: 'flagged',
          sortField: 'flagged'
        },
        {
          name: 'blocked',
          title: 'frozen',
          sortField: 'blocked'
        },
        {
          name: 'approved',
          title: 'reviewed',
          sortField: 'approved'
        },
        {
          name: 'disabled',
          title: 'active',
          sortField: 'disabled'
        },
        {
          name: 'response_text',
          title: 'response text',
          sortField: 'response_text'
        },
      ],
    }
  },
  computed: {
    categoryDescriptions: function () {
      return this.categoryReport.map((value) => value.description);
    }
  },
  methods: {
    download(extention) {
      this.fitleExtention = extention;
      if (this.selectedCatg !== '')
        EventBus.emit('show-modal-download', { title: 'Download PDF', downloadStarted: 'download started'});
    },
    downloadProcess(data, extentionFile) {
      const fileName = this.selectedCatg === 'main-topic' ? 'main topic' : 'customer';
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.${extentionFile}`);
      document.body.appendChild(link);
      link.click();
    },
    generateExcelReport() {
      if (this.selectedCatg === 'main-topic') {
        this.$dhDataProvider.getList('customResponseReportByTopic', { topic: this.categoryDescriptions,
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'Content-disposition': 'attachment',
            'format_file': 'xlsx'
          }})
          .then(response => {
            if (this.fitleExtention.trim() !== '') {
              this.fitleExtention = ''
              this.downloadProcess(response, 'xlsx');
            }
          })
          .catch((error) => this.notifyError(error))
          .finally(() => this.hideDownloadIndicator())
      } else if (this.selectedCatg === 'customer') {
        this.$dhDataProvider.get('customResponseCustomer', { responseType: 'blob',
          headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'Content-disposition': 'attachment',
            'format_file': 'xlsx'
          }})
          .then(response => {
            if (this.fitleExtention.trim() !== '') {
              this.fitleExtention = ''
              this.downloadProcess(response, 'xlsx');
            }
          })
          .catch((error) => this.notifyError(error))
          .finally(() => this.hideDownloadIndicator())
      }
    },
    setCategoryValue(value) {
      this.isMainTopic = value.length > 0 ? true : false;
      this.setCategoryReport(null)
      if (!value) return
      this.setCategoryReport(value)
    },
    onDownload() {
      if (this.selectedCatg === 'main-topic' && !this.isMainTopic) {
        this.notifyError('please select a main topic to continue with the dowloand')
        return;
      }
      this.download('xlsx')
    },
    cancel() {
      this.setCategoryReport(null)
      this.redirect('custom_responses.index')
    },
  },
  validations: {
    responseText: 'required',
  },
  created() {
    this.isMainTopic = this.categoryReport && this.categoryReport.length > 0 ? true : false
    this.initValidator()
  },
  mounted() {
    EventBus.on('download-pdf', () => {
      switch (this.fitleExtention) {
        case 'xlsx':
          this.generateExcelReport()
          break;
        default:
          this.fitleExtention = ''
          break;
      }
    })
  },
  unmounted() {
    this.setCategoryReport(null)
  }
}
</script>
<style scoped>
.button-color{
  border-color: var(--highlightColor500);
  background-color: var(--highlightColor300);
}
  .form {
    width: 55%;
  }
  ul:before {
    content: attr(aria-label);
    font-size: 1rem;
    font-weight: bold;
    color: black;
  }
  ul > li {
    @apply py-3 font-light text-black;
  }
  .activeCatg {
    background-color: var(--highlightColor500);
    @apply text-white border-transparent;
  }
</style>
